@import 'material-icons/iconfont/round.css';
@import 'material-icons/iconfont/outlined.css';
@import '_reboot';
@import '_typography';
@import '_reused';
@import '_bootstrap-restyle';
@import '_components';
@import '_composed';
@import '_prime-overwrite';
@import '_flags';


:root {
  --l-secondary: var(--bluegray-500);
  --l-success:var(--green-500);
  --l-warn:#f59e0b;
  --l-danger:var(--red-500);
  --l-info:var(--blue-500);
  --l-unknown:var(--gray-400);
  --l-dark-blue: #022354;
}

html {
  font-size: 14px;
}
@media screen and (max-width: 450px) {
  html {
    font-size: 13.5px;
  }
}

body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--surface-ground);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  line-height: 1.5;
}




// @import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
// @import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
// @import '../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
// @import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
// @import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
// @import '../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
// @import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
// @import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
// @import '../../../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css';